::-webkit-calendar-picker-indicator {
  background: orange;
}
@keyframes shake {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.exciter {
  animation-name: shake;
  animation-duration: 1s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.36,.07,.19,.97);
  animation-iteration-count: 2;
  animation-delay: 5s;
  transform: translate3d(0, 0, 0);
}